/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

import { createMemoInstruction } from '@solana/spl-memo'
import { withdrawSol, withdrawStake } from '@solana/spl-stake-pool'
import { StakeProgram } from '@solana/web3.js'
import { useDebounce } from '@vueuse/core'
import { Notify } from 'quasar'
import { useAnchorWallet, useWallet } from 'solana-wallets-vue'
import { computed, ref, toRef, watch } from 'vue'
import type { ValidatorAccount } from '@solana/spl-stake-pool/dist/utils'
import type { Signer, TransactionInstruction } from '@solana/web3.js'
import { WITHDRAW_SOL_ACTIVE } from '~/config'
import { withPriorityFees } from '~/features/priority-fee'
import { useMonitorTransaction } from '~/hooks'
import {
  useConnectionStore,
  useStakeAccountStore,
  useStakePoolStore,
} from '~/store'
import { getWalletEphemeralSignerPubkey, sendTransaction, sendTransactions, solToLamports } from '~/utils'
import { getWithdrawList } from '~/utils/api'

export function useWithdraw() {
  const connectionStore = useConnectionStore()
  const directStakeStore = useDirectStakeStore()
  const stakePoolStore = useStakePoolStore()
  const stakeAccountStore = useStakeAccountStore()

  const balanceStore = useBalanceStore()
  const tokenBalance = computed(() => balanceStore.tokenBalance)
  const availableJsol = computed(() => (tokenBalance.value ? tokenBalance.value : 0))

  const { monitorTransaction, sending } = useMonitorTransaction()

  const { publicKey, connected } = useWallet()
  const anchorWallet = useAnchorWallet()

  const lamportsPerSignature = toRef(stakePoolStore, 'lamportsPerSignature')
  const stakePool = toRef(stakePoolStore, 'stakePool')
  const reserveStakeBalance = toRef(stakePoolStore, 'reserveStakeBalance')

  const useReserve = ref(false)
  const useWithdrawSol = ref(true)
  const loading = ref(false)
  const amount = ref(0)

  watch([useDebounce(amount, 100), reserveStakeBalance], async ([amount]) => {
    if (WITHDRAW_SOL_ACTIVE && stakePool.value?.reserveStake) {
      const poolAmount = solToLamports(amount)
      if (reserveStakeBalance.value > poolAmount) {
        useWithdrawSol.value = true
        return
      }
    }
    useWithdrawSol.value = false
  }, { immediate: true })

  return {
    amount,
    useWithdrawSol,
    setAmount: (val: number) => (amount.value = Number(val)),
    useReserve: (val = true) => (useReserve.value = val),
    // TODO: calc with priority fee?
    withdrawFee: computed(() => lamportsPerSignature.value * 3),
    withdrawSolFee: computed(() => lamportsPerSignature.value * 2),
    withdrawing: computed(() => loading.value || sending.value),
    withdraw: async (forceDelayed = false, directStakeId?: number) => {
      if (!connected.value) {
        throw new Error('Wallet not connected')
      }

      if (amount.value <= 0) {
        return false
      }

      if (directStakeId && availableJsol.value < amount.value) {
        amount.value = availableJsol.value
      }

      try {
        loading.value = true

        // Try to use "withdraw sol"
        if (useWithdrawSol.value && !forceDelayed) {
          console.log('--------------------------')
          console.log('|     WITHDRAW SOL        ')
          console.log('--------------------------')

          // used for multisig
          const ephemeralSignerAddress = await getWalletEphemeralSignerPubkey()

          const { instructions, signers } = await withdrawSol(
            connectionStore.connection,
            connectionStore.stakePoolAddress!,
            publicKey.value!,
            publicKey.value!,
            amount.value,
            undefined,
            ephemeralSignerAddress,
          )

          if (directStakeId) {
            instructions.push(createMemoInstruction(`direct-unstake:${directStakeId}`))
          }

          await monitorTransaction(
            sendTransaction(
              connectionStore.connection,
              anchorWallet.value!,
              await withPriorityFees({ instructions }),
              signers,
            ),
            {
              onSuccess: (signature) => {
                if (directStakeId) {
                  directStakeStore.registerStake(signature)
                }
              },
            },
          )

          return true
        }

        const withdrawList = await getWithdrawList()
        const { instructions, signers } = await withdrawStake(
          connectionStore.connection,
          connectionStore.stakePoolAddress!,
          publicKey.value!,
          amount.value,
          useReserve.value,
          undefined,
          undefined,
          undefined,
          await prepareWithdrawStakeComparator(withdrawList),
          // prepareWithdrawStakeLimiter(withdrawList),
        )

        const instructionSet: TransactionInstruction[][] = []
        const signerSet: Signer[][] = []
        const labels: string[] = []

        // Token.createApproveInstruction
        // instructionSet.push([instructions[0]!])
        // signerSet.push([])
        // labels.push('Approve')

        for (let i = 1, s = 1; s < signers.length; i += 2, s++) {
          const instructionsChunk = [
            // SystemProgram.createAccount
            instructions[i]!,
            // StakePoolInstruction.withdrawStake
            instructions[i + 1]!,
            ...StakeProgram.deactivate({
              stakePubkey: signers[s]!.publicKey,
              authorizedPubkey: publicKey.value!,
            }).instructions,
          ]
          if (directStakeId && s === 1) {
            instructionsChunk.push(createMemoInstruction(`direct-unstake:${directStakeId}`))
          }
          instructionSet.push(instructionsChunk)

          signerSet.push([signers[0]!, signers[s]!])
          labels.push(`Unstake #${s}`)
        }

        // Token.createApproveInstruction
        if (instructionSet[0]) {
          instructionSet[0]!.unshift(instructions[0]!)
        }

        // apply priority fees
        for (let i = 0; i < instructionSet.length; i++) {
          instructionSet[i] = await withPriorityFees({ instructions: instructionSet[i]! })
        }

        // console.log('instructionSet', instructionSet)

        try {
          await sendTransactions(
            connectionStore.connection,
            anchorWallet.value!,
            instructionSet,
            signerSet,
            {
              stopOnError: true,
              onSuccess: async (txId: string, idx: number) =>
                monitorTransaction(txId, {
                  idx: labels[idx],
                  onSuccess: (signature) => {
                    if (directStakeId) {
                      directStakeStore.registerStake(signature)
                    }
                  },
                  // onError(e) {
                  //   throw e
                  // },
                }),
            },
          )
        } catch {
          // console.log(e)
        }

        // reload stake accounts
        stakeAccountStore.load().then()

        return true
      } catch (e: any) {
        Notify.create({ message: e.message, type: 'negative' })
        // throw e
      } finally {
        loading.value = false
      }
    },
  }
}

type VoteId = string

async function prepareWithdrawStakeComparator(stakeMap: Record<VoteId, number>) {
  if (Object.keys(stakeMap).length === 0) {
    return
  }
  return (a: ValidatorAccount, b: ValidatorAccount) => {
    const aVoteId = a.voteAddress?.toBase58()
    const bVoteId = b.voteAddress?.toBase58()
    if (!aVoteId || !bVoteId) {
      return 0
    }
    const aStake = stakeMap[aVoteId] ?? 0
    const bStake = stakeMap[bVoteId] ?? 0
    return bStake - aStake
  }
}

// function prepareWithdrawStakeLimiter(stakeMap: Record<VoteId, number>) {
//   if (Object.keys(stakeMap).length === 0) {
//     return
//   }
//   return (acc: ValidatorAccount): number => {
//     const voteId = acc.voteAddress?.toBase58() ?? ''
//     return Math.max(0, stakeMap[voteId] ?? acc.lamports)
//   }
// }

// /**
//  * Try to use APY comparator instead of lamport
//  * @returns {Promise<((a: ValidatorAccount, b: ValidatorAccount) => number) | undefined>}
//  * @param validators
//  */
// async function prepareApyComparator(validators: Array<ValidatorData>) {
//   if (!validators) {
//     return
//   }
//
//   try {
//     if (validators.length > 0) {
//       const voteApyMap = validators.reduce<Record<string, number>>((res, v) => {
//         res[v.voteId] = v.apy
//         return res
//       }, {})
//       console.log('Use APY Comparator')
//       return (a: ValidatorAccount, b: ValidatorAccount) => {
//         const aVoteId = a.voteAddress?.toBase58()
//         const bVoteId = b.voteAddress?.toBase58()
//         if (!aVoteId || !bVoteId) {
//           return 0
//         }
//         const aApy = parseFloat(String(voteApyMap[aVoteId]))
//         const bApy = parseFloat(String(voteApyMap[bVoteId]))
//         // console.log(`Compare ${aVoteId} (${aApy}) and ${bVoteId} (${bApy})`);
//         // if (isNaN(aApy) || isNaN(bApy)) {
//         //   return defaultResult;
//         // }
//         return aApy - bApy
//       }
//     }
//   } catch (e) {
//     console.log('Error:', e)
//   }
// }
